import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: () =>
      import(/* webpackChunkName: "baseLayout" */ "@/layouts/BaseLayout.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "/home",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/auth/LoginView.vue"),
        meta: {
          notLogged: true,
          requiresAuth: false,
          title: "Login"
        }
      },
      {
        path: "/signup",
        name: "signup",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/auth/RegisterView.vue"
          ),
        meta: {
          notLogged: true,
          requiresAuth: false,
          title: "Registro"
        }
      },
      {
        path: "/recuperar",
        name: "recuperar",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/auth/RecoverPassView.vue"
          ),
        meta: {
          notLogged: true,
          requiresAuth: false,
          title: "Recuperar"
        }
      },
      {
        path: "/reenviar",
        name: "reenviar",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/auth/ValidateAccountView.vue"
          ),
        meta: {
          notLogged: true,
          requiresAuth: false,
          title: "Reenviar"
        }
      },
      {
        path: "/beneficios",
        name: "Beneficios",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Beneficios/HomeBeneficiosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Beneficios"
        }
      },
      {
        path: "/categorias",
        name: "Categorias",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Beneficios/CategoriasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Categorias"
        }
      },
      {
        path: "/convenios/:nombre",
        name: "Comercios",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Beneficios/ConveniosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Comercios"
        }
      },

      {
        path: "/beneficio/:nombre",
        name: "Beneficio",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Beneficios/BeneficioView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Beneficio"
        }
      },
      {
        path: "/socios",
        name: "Socios",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Socios/SociosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Registrar como Socio"
        }
      },
      {
        path: "/contacto",
        name: "Contacto",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Contacto/ContactoView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Contacto"
        }
      },
      {
        path: "/terminos",
        name: "Terminos",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Terminos/TerminosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Términos y Condiciones"
        }
      },
      {
        path: "/iquique-emprende",
        name: "CategoriasEmp",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Emprendimientos/CategoriasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Categorias Emprendimientos"
        }
      },
      {
        path: "/iquique-emprende/:nombre",
        name: "Emprendimientos",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Emprendimientos/EmprendimientosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Emprendimientos"
        }
      },
      {
        path: "/detalle/iquique-emprende/:nombre",
        name: "DetalleEmp",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Emprendimientos/DetalleEmpView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Perfil Emprendimiento"
        }
      },
      {
        path: "/vecinos",
        name: "ApplicationsList",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Dashboard/ApplicationsListView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Solicitudes"
        }
      },
      {
        path: "/validar",
        name: "Applications",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/components/Dashboard/Applications.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Validar"
        }
      },
      {
        path: "/solicitud/editar",
        name: "ApplicationEdit",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/components/Dashboard/Applications.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Editar"
        }
      },
      {
        path: "detalles/:id",
        name: "ApplicationDetails",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/components/Dashboard/ApplicationDetail.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Detalles"
        }
      },
      {
        path: "/solicitud",
        name: "solicitud",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Dashboard/NewApplicationView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Solicitud"
        }
      },
      {
        path: "/perfil",
        name: "perfil",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/menu/MisDatosView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Perfil"
        }
      },
      {
        path: "/editar",
        name: "editar",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/menu/editarPerfilView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Editar"
        }
      },
      {
        path: "/cuenta/eliminar",
        name: "EliminarCuenta",
        component: () =>
          import(
            /* webpackChunkName: "encuesta" */ "@/views/Web/EliminarCuentaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/configuracion",
        name: "configuracion",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/menu/ConfiguracionView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Configuración"
        }
      },
      {
        path: "/descargar",
        name: "Descarga",
        component: () =>
          import(
            /* webpackChunkName: "Descarga" */ "@/views/Descarga/DescargaView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Tarjeta Miquique"
        }
      },
      {
        path: "/auth",
        name: "Auth",
        component: () =>
          import(/* webpackChunkName: "Auth" */ "@/views/auth/AuthView.vue"),
        meta: {
          requiresAuth: false,
          title: "Tarjeta Iquique"
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const authenticated = Store.state.authenticated;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  const notLogged = to.matched.some(record => record.meta.notLogged);

  if (requiresAuth && !authenticated) {
    next("/login");
  } else if (!requiresAuth && authenticated) {
    if (notLogged) {
      next("/vecinos");
    } else {
      next();
    }
  } else {
    next();
  }
});
const DEFAULT_TITLE = "Tarjeta Iquique";
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    if (to.meta) {
      document.title = to.meta.title || DEFAULT_TITLE;
    }
  });
});
export default router;
